<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-briefcase-account</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <v-tabs>
            <v-tab> Básicos </v-tab>
            <v-tab> NFSe </v-tab>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.id"
                        label="ID"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="10" align-self="center">
                      <v-text-field
                        dense
                        :error-messages="nomeFantasiaErrors"
                        hide-details="auto"
                        v-model="varPJuridicaLocal.nomeFantasia"
                        label="Nome Fantasia"
                        outlined
                        requered
                        @input="$v.varPJuridicaLocal.nomeFantasia.$touch()"
                        @blur="$v.varPJuridicaLocal.nomeFantasia.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="9" align-self="center">
                      <v-text-field
                        dense
                        :error-messages="razaoSocialErrors"
                        hide-details="auto"
                        v-model="varPJuridicaLocal.razaoSocial"
                        label="Razao Social"
                        outlined
                        required
                        @input="$v.varPJuridicaLocal.razaoSocial.$touch()"
                        @blur="$v.varPJuridicaLocal.razaoSocial.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" align-self="center">
                      <v-text-field
                        dense
                        :error-messages="cnpjErrors"
                        hide-details="auto"
                        v-model="varPJuridicaLocal.cnpj"
                        label="CNPJ"
                        v-mask="'##.###.###/####-##'"
                        outlined
                        required
                        @input="$v.varPJuridicaLocal.cnpj.$touch()"
                        @blur="$v.varPJuridicaLocal.cnpj.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.inscricaoMunicipal"
                        label="Inscrição Municipal"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.inscricaoEstadual"
                        label="Inscrição Estadual"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.cep"
                        label="CEP"
                        v-mask="'#####-###'"
                        outlined
                        @blur="metObterDadosCep(varPJuridicaLocal.cep)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.endereco"
                        label="Endereço"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.numero"
                        label="Número"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.complemento"
                        label="Complemento"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.bairro"
                        label="Bairro"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-autocomplete
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.idPais"
                        label="País"
                        :items="compPaisesSelect"
                        item-text="nome_pt"
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.cidade"
                        label="Cidade"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.uf"
                        label="UF"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.email"
                        label="E-mail"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.telefone"
                        label="Telefone"
                        v-mask="metMaskFone(varPJuridicaLocal.telefone)"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="12" align-self="center">
                      <v-textarea
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.observacoes"
                        label="Observações"
                        rows="2"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varPJuridicaLocal.homologacao"
                        label="Homologado"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.login"
                        label="Login"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.password"
                        label="Password"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varPJuridicaLocal.incentivadorCultural"
                        label="Incentivador Cultural"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.naturezaOperacao"
                        label="Natureza Operação"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varPJuridicaLocal.optanteSimplesNacional"
                        label="Optante Simples Nacional"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.regimeEspecialTributacao"
                        label="Regime Especial Tributação"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.nfseCodMunicipio"
                        label="Código Município"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="7" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.nfseMunicipio"
                        label="Município"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.nfseUf"
                        label="UF"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.fonePrefeitura"
                        label="Telefone"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.sitePrefeitura"
                        label="Site"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.loginPrefeitura"
                        label="Login Prefeitura"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varPJuridicaLocal.senhaPrefeitura"
                        label="Senha Prefeitura"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
          </v-tabs>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ValidaCPFCNPJMixin from "@/mixins/ValidaCPFCNPJMixin";
import DatasMixin from "@/mixins/DatasMixin";
import axios from "../_services/axios";

const { mapState, mapActions } = createNamespacedHelpers("finPJuridicas");

export default {
  mixins: [validationMixin, DatasMixin, ValidaCPFCNPJMixin],

  data: () => ({
    name: "FinPJuridicasForm",
    tituloForm: "",
    varPJuridicaLocal: {},
  }),

  validations: {
    varPJuridicaLocal: {
      nomeFantasia: { required },
      razaoSocial: { required },
      cnpj: {
        required,
        validCNPJ: function (value) {
          return this.mixValidaCpfCnpj(value);
        },
      },
    },
  },

  computed: {
    ...mapState(["staPJuridicaSelect", "staPaisesSelect"]),

    compPaisesSelect() {
      return this.staPaisesSelect;
    },

    nomeFantasiaErrors() {
      const errors = [];
      if (!this.$v.varPJuridicaLocal.nomeFantasia.$dirty) return errors;
      !this.$v.varPJuridicaLocal.nomeFantasia.required &&
        errors.push("Nome Fantasia é obrigatório.");
      return errors;
    },

    razaoSocialErrors() {
      const errors = [];
      if (!this.$v.varPJuridicaLocal.razaoSocial.$dirty) return errors;
      !this.$v.varPJuridicaLocal.razaoSocial.required &&
        errors.push("Razão Social é obrigatório.");
      return errors;
    },

    cnpjErrors() {
      const errors = [];
      if (!this.$v.varPJuridicaLocal.cnpj.$dirty) return errors;
      if (!this.$v.varPJuridicaLocal.cnpj.required) {
        errors.push("CNPJ é obrigatório.");
      }
      if (!this.$v.varPJuridicaLocal.cnpj.validCNPJ) {
        errors.push("CNPJ inválido.");
      }
      return errors;
    },
  },

  watch: {
    staPJuridicaSelect(pjuridicaNovo) {
      this.metSincronizar(pjuridicaNovo);
    },
  },

  created() {
    this.metSincronizar(this.staPJuridicaSelect);
    if (this.staPJuridicaSelect) {
      this.tituloForm = "Editar Pessoa Jurídica";
    } else {
      this.tituloForm = "Nova Pessoa Jurídica";
    }
    this.actPaisesSelect();
  },

  methods: {
    ...mapActions([
      "actSelecionarPJuridica",
      "actResetarPJuridica",
      "actCriarPJuridica",
      "actEditarPJuridica",
      "actResetarErro",
      "actPaisesSelect",
    ]),

    async metObterDadosCep(cep) {
      if (cep) {
        try {
          var dadosCep = await axios.getObterDadosCep(cep);
          this.varPJuridicaLocal.endereco =
            dadosCep.data.tipo_logradouro + " " + dadosCep.data.nome_logradouro;
          this.varPJuridicaLocal.bairro = dadosCep.data.bairro;
          this.varPJuridicaLocal.cidade = dadosCep.data.nome_localidade;
          this.varPJuridicaLocal.uf = dadosCep.data.uf;
        } catch (err) {
          console.log(err);
        }
      }
    },

    metMaskFone(fone) {
      if (fone == null) {
        return "";
      }
      if (fone.includes("(")) {
        return fone.length <= 13 ? "(##)####-####" : "(##)# ####-####";
      } else {
        return fone.length <= 10 ? "(##)####-####" : "(##)# ####-####";
      }
    },

    selecionarPJuridicaEdicao(pjuridica) {
      this.actSelecionarPJuridica({ pjuridica });
    },

    async salvarPJuridica() {
      switch (this.staPJuridicaSelect) {
        case undefined:
          this.varPJuridicaLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varPJuridicaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarPJuridica({
            pjuridica: this.varPJuridicaLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.finPJuridicas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.finPJuridicas.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Pessoa Jurídica";
            this.$root.snackbar.show({
              type: "success",
              message: "Pessoa Jurídica Criada!",
            });
          }
          break;
        default:
          this.varPJuridicaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varPJuridicaLocal.criado = undefined;
          this.varPJuridicaLocal.criacao = undefined;
          this.varPJuridicaLocal.modificado = undefined;
          await this.actEditarPJuridica({
            pjuridica: this.varPJuridicaLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.finPJuridicas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.finPJuridicas.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Pessoa Jurídica Salva!",
            });
          }
      }
      //this.resetar();
    },

    metSincronizar(pjuridica) {
      this.varPJuridicaLocal = Object.assign(
        {},
        pjuridica || {
          id: null,
          endereco: null,
          bairro: null,
          cidade: null,
          uf: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarPJuridica();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varPJuridicaLocal.ultima_compra = this.staPJuridicaSelect.ultima_compra;
      // this.varPJuridicaLocal.email = this.staPJuridicaSelect.email;
      // this.varPJuridicaLocal.pjuridica = this.staPJuridicaSelect.pjuridica;
      // this.varPJuridicaLocal.senha = this.staPJuridicaSelect.senha;
      // this.varPJuridicaLocal.ativo = this.staPJuridicaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
